import React from "react";
import {Menu, Transition} from "@headlessui/react";
import {Bars3Icon, XMarkIcon} from "@heroicons/react/20/solid";
import calendly from "../images/Calendly.svg";

export default function SideBar() {
  return (
    <React.Fragment>
      <aside className=" hidden h-full bg-[#16161C] px-10 py-10 md:flex flex-col justify-between text-white flex-none max-w-xs w-full relative">
        <h3 className="font-bold text-2xl xl:text-[32px] mb-8 xl:mb-16">
          Truecolo
          <span className="text-[#fe3939]">r</span>
        </h3>

        <div className="flex items-center gap-4">
          <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="52"
              height="52"
              viewBox="0 0 48 48"
            >
              <path
                fill="#fff"
                d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
              ></path>
              <path
                fill="#16161C"
                d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
              ></path>
            </svg>
            <a
              href="https://www.linkedin.com/in/opeyemi-john-486936199/"
              target="_blank"
              rel="noreferrer"
              className="absolute opacity-0 inset-0"
            >
              .
            </a>
          </button>
          <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="46"
              height="46"
              viewBox="0,0,256,256"
            >
              <g
                fill="none"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(5.12,5.12)">
                  <path
                    d="M11,4c-3.866,0 -7,3.134 -7,7v28c0,3.866 3.134,7 7,7h28c3.866,0 7,-3.134 7,-7v-28c0,-3.866 -3.134,-7 -7,-7zM13.08594,13h7.9375l5.63672,8.00977l6.83984,-8.00977h2.5l-8.21094,9.61328l10.125,14.38672h-7.93555l-6.54102,-9.29297l-7.9375,9.29297h-2.5l9.30859,-10.89648zM16.91406,15l14.10742,20h3.06445l-14.10742,-20z"
                    fill="white"
                    stroke="white"
                    stroke-width="1"
                  ></path>
                  <path
                    d="M13.08594,13h7.9375l5.63672,8.00977l6.83984,-8.00977h2.5l-8.21094,9.61328l10.125,14.38672h-7.93555l-6.54102,-9.29297l-7.9375,9.29297h-2.5l9.30859,-10.89648"
                    fill="#16161C"
                    stroke="#16161C"
                    stroke-width="1"
                  ></path>
                </g>
              </g>
            </svg>
            <a
              href="https://twitter.com/Opeyemi__A"
              target="_blank"
              rel="noreferrer"
              className="absolute opacity-0 inset-0"
            >
              .
            </a>
          </button>
          <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
            <img src={calendly} alt="" />
            <a
              href="https://calendly.com/truecolor/15min"
              target="_blank"
              rel="noreferrer"
              className="absolute opacity-0 inset-0"
            >
              .
            </a>
          </button>
          <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden fill-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 512 512"
            >
              <path
                d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                fill="#16161C"
                stroke="#16161C"
                stroke-width="1"
              />
            </svg>
            <a
              href="mailto:opeyemi@truecolor.io"
              className="absolute opacity-0 inset-0"
            >
              .
            </a>
          </button>
        </div>
      </aside>
      <div className="md:hidden z-30">
        <Menu>
          {({open}) => (
            <>
              <Menu.Button>
                {open ? (
                  <XMarkIcon className="w-9 h-9 text-black absolute top-4 right-4" />
                ) : (
                  <Bars3Icon className="w-9 h-9 text-white absolute top-4 right-4" />
                )}
              </Menu.Button>
              <Transition
                enter="transition duration-400 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-200 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Menu.Items className="absolute top-8 left-0 w-screen h-screen bg-[#16161C] p-10 flex flex-col justify-between text-white pb-20">
                  <h3 className="font-bold text-2xl xl:text-[32px] mb-8 xl:mb-16">
                    Truecolo
                    <span className="text-[#fe3939]">r</span>
                  </h3>

                  <div className="flex items-center gap-4">
                    <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="52"
                        height="52"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#fff"
                          d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                        ></path>
                        <path
                          fill="#16161C"
                          d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                        ></path>
                      </svg>
                      <a
                        href="https://www.linkedin.com/in/opeyemi-john-486936199/"
                        target="_blank"
                        rel="noreferrer"
                        className="absolute opacity-0 inset-0"
                      >
                        .
                      </a>
                    </button>
                    <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="46"
                        height="46"
                        viewBox="0,0,256,256"
                      >
                        <g
                          fill="none"
                          fill-rule="nonzero"
                          stroke="none"
                          stroke-width="1"
                          stroke-linecap="butt"
                          stroke-linejoin="miter"
                          stroke-miterlimit="10"
                          stroke-dasharray=""
                          stroke-dashoffset="0"
                          font-family="none"
                          font-weight="none"
                          font-size="none"
                          text-anchor="none"
                        >
                          <g transform="scale(5.12,5.12)">
                            <path
                              d="M11,4c-3.866,0 -7,3.134 -7,7v28c0,3.866 3.134,7 7,7h28c3.866,0 7,-3.134 7,-7v-28c0,-3.866 -3.134,-7 -7,-7zM13.08594,13h7.9375l5.63672,8.00977l6.83984,-8.00977h2.5l-8.21094,9.61328l10.125,14.38672h-7.93555l-6.54102,-9.29297l-7.9375,9.29297h-2.5l9.30859,-10.89648zM16.91406,15l14.10742,20h3.06445l-14.10742,-20z"
                              fill="white"
                              stroke="white"
                              stroke-width="1"
                            ></path>
                            <path
                              d="M13.08594,13h7.9375l5.63672,8.00977l6.83984,-8.00977h2.5l-8.21094,9.61328l10.125,14.38672h-7.93555l-6.54102,-9.29297l-7.9375,9.29297h-2.5l9.30859,-10.89648"
                              fill="#16161C"
                              stroke="#16161C"
                              stroke-width="1"
                            ></path>
                          </g>
                        </g>
                      </svg>
                      <a
                        href="https://twitter.com/Opeyemi__A"
                        target="_blank"
                        rel="noreferrer"
                        className="absolute opacity-0 inset-0"
                      >
                        .
                      </a>
                    </button>
                    <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden">
                      <img src={calendly} alt="" />
                      <a
                        href="https://calendly.com/truecolor/15min"
                        target="_blank"
                        rel="noreferrer"
                        className="absolute opacity-0 inset-0"
                      >
                        .
                      </a>
                    </button>
                    <button className="relative w-10 h-10 bg-white rounded-lg flex items-center justify-center overflow-hidden fill-white">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"
                          fill="#16161C"
                          stroke="#16161C"
                          stroke-width="1"
                        />
                      </svg>
                      <a
                        href="mailto:opeyemi@truecolor.io"
                        className="absolute opacity-0 inset-0"
                      >
                        .
                      </a>
                    </button>
                  </div>
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>
      </div>
    </React.Fragment>
  );
}
